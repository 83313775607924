<template>
  <div class="page">
    <div class="p-footer h-end">
      <button class="btn auto fill ml-3"
              @click="toIndex()">返回首页</button>
    </div>
    <div class="s1 p-card fs-16 m-3">
      <div class="flex h-between p-3">
        <div>入群欢迎语</div>
        <div>
          <input v-model="status"
                 type="checkbox"
                 class="switch"
                 @change="statusChange">
        </div>
      </div>
      <div class="divider-dashed ml-3" />
      <div class="flex h-between p-3 pointer"
           @click="goGroup">
        <div>生效群数（{{ chatroomList.length }}）</div>
        <div>
          <div class="p-icon-arrow" />
        </div>
      </div>
    </div>
    <div class="s2 p-card fs-16 m-3">
      <div class="flex h-between p-3 pointer"
           @click="goEdit(1)">
        <div>欢迎语内容（{{ contentMsgs.length }}）</div>
        <div>
          <div class="p-icon-arrow" />
        </div>
      </div>
      <div class="divider-dashed ml-3" />
      <div class="b2">
        <no-data v-if="contentMsgs.length===0"
                 title="暂未添加入群欢迎语"
                 btn-text="新增入群欢迎语"
                 @click="goEdit(0)" />
        <div v-for="(item,index) in contentMsgs"
             :key="index"
             class="item">
          <msg-box :data="item" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import msgBox from '@/components/msg-box/index.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'WelcomeWords',
  components: { msgBox },
  data () {
    return {
      status: false,
      chatroomList: [],
      contentMsgs: []
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$get('/JoinChatroomGreet/GetJoinChatroomGreet').then(res => {
        this.chatroomList = res.chatroomList
        this.contentMsgs = res.contentList
        this.status = res.status === 1
      })
    },
    statusChange () {
      this.$get('/JoinChatroomGreet/JoinChatroomGreetStatusSetting', {
        status: this.status ? 1 : 0
      }).then(res => {
        this.$Toast('切换成功')
      })
    },
    goGroup () {
      this.$store.commit('setGlobalData', {
        chatroomList: this.chatroomList
      })
      this.$router.push('/selectGroup')
    },
    goEdit (n) {
      if (n) {
        this.$store.commit('setGlobalData', {
          contentMsgs: this.contentMsgs
        })
      }
      this.$router.push({
        path: '/welcomeEdit',
        query: {
          isEdit: n
        }
      })
    },
    toIndex () {
      const _this = this
      _this.$router.replace('/index')
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
